<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Address
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="customer-form">
          <v-text-field
            label="Annual income from all PAYE or self employments:"
            v-model="fields.annual_income"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('annual_income')"
            :error-messages="errors['annual_income']"
          ></v-text-field>
          <v-text-field
            label="Apart from PAYE or self employment income, do you receive any other sources of income (benefit payments / pension / etc):"
            v-model="fields.other_income"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('other_income')"
            :error-messages="errors['other_income']"
          ></v-text-field>
          <v-text-field
            label="Savings:"
            v-model="fields.savings"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('savings')"
            :error-messages="errors['savings']"
          ></v-text-field>
          <v-text-field
            label="Any additional information regarding your income status:"
            v-model="fields.professional_additional_income_status"
            type="text"
            outlined
            background-color="white"
            :error="
              errors.hasOwnProperty('professional_additional_income_status')
            "
            :error-messages="errors['professional_additional_income_status']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      form: {},
      fields: {
        form_title: "financial",
        annual_income: null,
        other_income: null,
        savings: null,
        professional_additional_income_status: null,
      },
      errors: {},
    };
  },

  computed: {
    availableCountries() {
      return Object.values(countries.getNames("en"));
    },
  },

  methods: {
    open(form = null) {
      if (form !== null) {
        this.form = form;
        this.isEditing = true;
        this.fields.annual_income = form.annual_income;
        this.fields.other_income = form.other_income;
        this.fields.savings = form.savings;
        this.fields.professional_additional_income_status =
          form.professional_additional_income_status;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.formId = this.form.application_id;
      }

      this.$store
        .dispatch("easylets/applicationformsStore/saveForm", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.form = {};
      this.fields = {
        annual_income: null,
        other_income: null,
        savings: null,
        professional_additional_income_status: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
