<template>
  <div>
    <v-card outlined class="mb-6">
      <v-card-subtitle class="d-flex justify-start align-center grey lighten-5">
        <div class="flex-grow-1">Financial Info</div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="blue lighten-4 blue--text"
              v-on="on"
              @click="$refs.financialDialog.open(form)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit Customer's Information</span>
        </v-tooltip>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <th>Annual income from all PAYE or self employments:</th>
              <td>{{ form.annual_income }}</td>
            </tr>
            <tr>
              <th>
                Apart from PAYE or self employment income, do you receive any
                other sources of income (benefit payments / pension / etc):
              </th>
              <td>{{ form.other_income }}</td>
            </tr>
            <tr>
              <th>Savings: £</th>
              <td>{{ form.savings }}</td>
            </tr>
            <tr>
              <th>Any additional information regarding your income status:</th>
              <td>
                {{ form.professional_additional_income_status }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <!--
 {{ form.professional_additional_income_status }}
        {{ form.professional_main_income_source }}
        {{ form.professional_employment_status }}

-->
      </v-card-text>
      <financial-dialog ref="financialDialog" />
    </v-card>
  </div>
</template>

<script>
import FinancialDialog from "./components/FinancialDialog.vue";

export default {
  components: {
    FinancialDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Forms",
          disabled: false,
          to: { name: "module-easylets-applicationforms" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    form() {
      return this.$store.getters["easylets/applicationformsStore/get"];
    },
  },
};
</script>
